import {Stack} from "@mui/material";
import {Edit, Labeled, SimpleForm, TextField, useRecordContext} from "react-admin";
import {SelectRoles} from "./components/SelectRoles";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";
import {SaveToolbar} from "../../shared/components/SaveToolbar/SaveToolbar";
import {BlockOperators} from "./components/BlockOperators/BlockOperator";
import {AdditionalErrors} from "./components/AdditionalErrors";
import {ALREADY_DISPLAYED_KEYS} from "./constants";

export const OperatorEdit = () => {
  return (
    <>
      <Edit redirect="list" mutationMode="pessimistic" title="Редактирование оператора">
        <SimpleForm toolbar={<SaveToolbar />}>
          <Stack sx={{maxWidth: "800px", width: "100%", gap: 5}}>
            <Labeled>
              <TextField source="id" label="ID" />
            </Labeled>

            <Labeled>
              <TextField source="fio" label="ФИО" />
            </Labeled>

            <Labeled>
              <TextField source="email" label="Email" />
            </Labeled>

            <Labeled>
              <TextField source="phoneNumber" label="Телефон" />
            </Labeled>

            <Labeled label="Заблокирован">
              <MyBooleanField source="isActive" sx={{justifyContent: "flex-start"}} />
            </Labeled>

            <Labeled>
              <TextField source="position" label="Должность" />
            </Labeled>

            <Labeled>
              <TextField source="department" label="Отдел" />
            </Labeled>

            <SelectRoleWrapper />

            <AdditionalErrors alreadyDisplayedKeys={ALREADY_DISPLAYED_KEYS} />
          </Stack>
        </SimpleForm>

        <BlockOperators />
      </Edit>
    </>
  );
};

function SelectRoleWrapper() {
  const record = useRecordContext();

  const operatorRoles = (record?.roles || []).map((role) => {
    const value = typeof role === "string" ? role : role.id;

    return value;
  });

  return (
    <SelectRoles operatorRoles={operatorRoles} isOperatorBlocked={record.isActive} />
  );
}
