import {BooleanInput, Create, SimpleForm, TextInput} from "react-admin";
import {Box} from "@mui/material";
import {MyRichTextInput} from "./components/MyRichTextInput";

export const FAQCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm>
        <BooleanInput source="status" label={"Опубликована"} name={"status"} />
        <TextInput source="slug" label={"Раздел"} name={"slug"} required={true} />
        <TextInput
          fullWidth={true}
          source="position"
          label={"Позиция"}
          name={"position"}
          required={true}
        />
        <TextInput
          fullWidth={true}
          source="question"
          label={"Вопрос"}
          name={"question"}
          required={true}
        />

        <Box sx={{width: "100%"}}>
          <MyRichTextInput label="Ответ" source="answer" fullWidth />
        </Box>
        <br />

        <br />
      </SimpleForm>
    </Create>
  );
};
