import React from "react";
import {Toolbar, SaveButton, ToolbarProps} from "react-admin";

export const SaveToolbar = (props: ToolbarProps) => {
  return (
    <Toolbar {...props} sx={{alignItems: "center !important"}}>
      <SaveButton />
    </Toolbar>
  );
};
