import {Box} from "@mui/material";
import React from "react";
import {ToolbarProps, Toolbar, SaveButton, DeleteWithConfirmButton} from "react-admin";

export const SaveWithDeleteToolbar = (props: ToolbarProps) => {
  return (
    <Toolbar
      {...props}
      sx={{
        alignItems: "center !important",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          maxWidth: "800px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SaveButton />
        <DeleteWithConfirmButton size="medium" />
      </Box>
    </Toolbar>
  );
};
