import {Stack, Button, Tooltip, List, TextField} from "@mui/material";
import {CustomSelect} from "../../components/CustomSelect";
import {Editor} from "@tiptap/react";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useDisclosure} from "shared/hooks/useDisclosure";
import {UI_Modal} from "shared/components/UI_Modal/UI_Modal";
import {useState} from "react";
import ErrorIcon from "@mui/icons-material/Error";
import {LinksInToolbar} from "pages/FAQ/components/LinksInToolbar";

export const MenuBar = ({editor}: {editor: Editor | null}) => {
  const {isOpen, onClose, onOpen} = useDisclosure();

  if (!editor) {
    return null;
  }

  const node = editor.state.selection.$head.parent;
  const marginTopValue = node.attrs?.marginTop;
  const marginBottomValue = node.attrs?.marginBottom;
  const iconValue = node.attrs?.icon;

  const handleSetUrlFromModal = (value: string) => {
    editor.commands.setNode("BlockWithBackground", {url: value});
    onClose();
  };

  return (
    <Stack
      direction="row"
      gap={2}
      sx={({palette}) => ({
        flexWrap: "wrap",
        position: "sticky",
        top: 0,
        zIndex: 9999,
        alignItems: "center",
        boxShadow:
          palette.mode === "light"
            ? "0px 1px 8px 0px rgba(217, 217, 217, 0.85)"
            : "0px 1px 8px 0px rgba(30, 30, 30, 0.85)",
        transition: "all 0.3s ease",
        ":hover": {
          boxShadow:
            palette.mode === "light"
              ? "0px 1px 8px 0px rgba(49, 172, 184, 0.4)"
              : "0px 1px 8px 0px rgba(49, 172, 184, 0.4)",
        },
        background: palette.background.paper,
        p: 4,

        button: {
          maxWidth: "182px",
          width: "100%",
        },
      })}
    >
      <Button
        onClick={() => editor.commands.setNode("WithLeftBorder")}
        variant="main"
        disabled={node.type.name === "WithLeftBorder"}
      >
        Блок с левой границей
      </Button>

      <Button
        onClick={() => editor.commands.setNode("DefaultText")}
        variant="main"
        disabled={node.type.name === "DefaultText"}
      >
        Стандартный текст
      </Button>

      <Button
        onClick={() => editor.commands.setNode("BoldText")}
        variant="main"
        disabled={node.type.name === "BoldText"}
      >
        Блок с жирным текстом
      </Button>

      <Button
        onClick={() => editor.commands.setNode("List")}
        variant="main"
        disabled={node.type.name === "List"}
      >
        Список
      </Button>

      <Button
        onClick={() => editor.commands.setNode("BigText")}
        variant="main"
        disabled={node.type.name === "BigText"}
      >
        Блок с большим текстом
      </Button>

      <Button
        onClick={onOpen}
        variant="main"
        disabled={node.type.name === "BlockWithBackground"}
        sx={{lineHeight: "110%"}}
      >
        Блок с бэкграундом и кнопкой
      </Button>

      <Button variant="main" onClick={() => editor.commands.toggleBold()}>
        Выделить жирным
      </Button>
      <Button
        onClick={() => {
          editor.commands.clearNodes();
          editor.commands.unsetAllMarks();
        }}
        variant="main"
      >
        Убрать стили у строки
      </Button>

      <CustomSelect
        options={["0px", "4px", "8px", "12px", "16px", "20px"]}
        onChange={(value) => {
          let name = "";
          if (editor.isActive("DefaultText")) name = "DefaultText";
          else if (editor.isActive("BoldText")) name = "BoldText";

          editor.commands.updateAttributes(name, {marginTop: value});
        }}
        label="Отступ сверху"
        value={marginTopValue as string}
        disabled={!marginTopValue}
      />

      <CustomSelect
        options={["0px", "4px", "8px", "12px", "16px", "20px"]}
        onChange={(value) => {
          let name = "";
          if (editor.isActive("DefaultText")) name = "DefaultText";
          else if (editor.isActive("BoldText")) name = "BoldText";

          if (name) editor.commands.updateAttributes(name, {marginBottom: value});
        }}
        label="Отступ снизу"
        value={marginBottomValue}
        disabled={!marginBottomValue}
      />

      <CustomSelect
        options={[
          {
            option: "info",
            label: <ErrorIcon color="primary" />,
          },
          {
            option: "support",
            label: <ContactSupportIcon color="primary" />,
          },
          {
            option: "check",
            label: <CheckCircleIcon color="primary" />,
          },
        ]}
        onChange={(value) => {
          editor.commands.setNode("ImportantText", {icon: value});
        }}
        label="Блок с иконкой"
        value={iconValue}
      />

      <LinksInToolbar editor={editor} />

      <Tooltip
        title={
          <List subheader={"Подсказки к пользованию:"}>
            <li>
              1) Отступы снизу и сверху есть только у стандартного текста и блока с жирным
              текстом
            </li>
            <li>
              2) Отступы у двух соседних элементов схлопываются и приравниваются к
              большему
            </li>
            <li>3) Enter - это переход на новую строку с созданием нового элемента</li>
            <li>
              4) Ctrl + Enter или Shift + Enter - это переход на новую строку внутри
              элемента
            </li>
            <li>
              5) Для запрета переноса можно использовать спец символ "&amp;nbsp;" вместо
              пробела
              <br />
              Например: для строки (п.13, статья 44, ФЗ-44). написать -
              (п.13,&amp;nbsp;статья&amp;nbsp;44,&amp;nbsp;ФЗ-44)
            </li>
          </List>
        }
      >
        <LiveHelpIcon
          color="primary"
          sx={{cursor: "help", position: "absolute", top: 0, right: 0}}
        />
      </Tooltip>

      <ChangeUrlModal
        isOpen={isOpen}
        onClose={onClose}
        handleSubmit={handleSetUrlFromModal}
      />
    </Stack>
  );
};

type ChangeUrlModalProps = {
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (str: string) => void;
};

function ChangeUrlModal({isOpen, onClose, handleSubmit}: ChangeUrlModalProps) {
  const [value, setValue] = useState("");

  return (
    <UI_Modal modalProps={{open: isOpen, onClose}}>
      <Stack gap={2} sx={{m: 2}}>
        <TextField
          label="Ссылка"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <Button variant="main" onClick={() => handleSubmit(value)}>
          Сохранить
        </Button>
      </Stack>
    </UI_Modal>
  );
}
