import {
  CreateButton,
  Datagrid,
  EditButton,
  List,
  NumberField,
  TextField,
  TopToolbar,
  useListContext,
  WrapperField,
} from "react-admin";
import Loader from "shared/components/Loader/Loader";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";
import {getDataGridStyles} from "shared/styles/dataGridStyles";

export const GroupTariffsList = () => {
  return (
    <List
      pagination={false}
      actions={
        <TopToolbar>
          <CreateButton variant="main" sx={{px: 2}} label="Создать группу тарифов" />
        </TopToolbar>
      }
    >
      <Fields />
    </List>
  );
};

function Fields() {
  const {isLoading = false} = useListContext();

  if (isLoading) return <Loader />;
  return (
    <Datagrid
      rowClick="show"
      bulkActionButtons={false}
      sx={({palette}) => ({
        ...getDataGridStyles(palette),
      })}
    >
      <TextField source="codename" label={"код"} sortable={false} />
      <TextField source="title" label={"Название"} sortable={false} />
      <TextField source="description" label={"Описание"} sortable={false} />
      <NumberField
        source="sorter"
        label={
          <>
            Порядковый <br /> номер
          </>
        }
        sortable={false}
      />
      <MyBooleanField source="publish" sortable={false} label={"Опубликована"} />
      <TextField
        source="internalComment"
        label={
          <>
            Внутренний <br /> комментарий
          </>
        }
        sortable={false}
      />

      <WrapperField textAlign="center" sortable={false}>
        <EditButton
          label=""
          size="small"
          sx={({palette}) => ({color: palette.primary.main})}
        />
      </WrapperField>
    </Datagrid>
  );
}
