import {Stack} from "@mui/material";
import {AdditionalErrors} from "pages/father/components/AdditionalErrors";

import {RichTextInput} from "ra-input-rich-text";
import {Edit, SimpleForm, TextInput, NumberInput, BooleanInput} from "react-admin";
import {MyRichTextInputToolbar} from "./components/MyRichTextInputToolbar";
import {ALREADY_DISPLAYED_KEYS} from "./constants";
import {SaveWithDeleteToolbar} from "shared/components/SaveWithDeleteToolbar/SaveWithDeleteToolbar";

export const GroupTariffsEdit = () => {
  return (
    <Edit redirect="list" mutationMode="pessimistic">
      <SimpleForm toolbar={<SaveWithDeleteToolbar />}>
        <Stack sx={{maxWidth: "800px", width: "100%", gap: 1}}>
          <TextInput source="codename" label="Идентификатор" fullWidth />
          <TextInput source="title" label="Название" />

          <RichTextInput
            toolbar={<MyRichTextInputToolbar />}
            label="Описание"
            source="description"
          />

          <NumberInput source="sorter" label="Порядковый номер" />

          <TextInput
            source="internalComment"
            label="Внутренний комментарий"
            multiline
            minRows={2}
          />

          <BooleanInput source="publish" label="Опубликовать" />

          <AdditionalErrors alreadyDisplayedKeys={ALREADY_DISPLAYED_KEYS} />
        </Stack>
      </SimpleForm>
    </Edit>
  );
};
