import {Stack, Tooltip} from "@mui/material";
import {AdditionalErrors} from "pages/father/components/AdditionalErrors";
import React, {useEffect} from "react";
import {
  BooleanInput,
  Create,
  DateField,
  NumberInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import {SaveToolbar} from "shared/components/SaveToolbar/SaveToolbar";
import {ALREADY_DISPLAYED_KEYS} from "./helpers/constants";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {useFormContext} from "react-hook-form";
import {useSearchParams} from "react-router-dom";

export const PromoCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm toolbar={<SaveToolbar />}>
        <Stack sx={{maxWidth: "800px", width: "100%", gap: 1}}>
          <TextInput source="companyId" label="Id Компании" fullWidth />
          <TextInput source="promoTariffId" label="Id Промо тарифа" fullWidth />

          {/* TODO: получить количество дней и название тарифа после ввода id - и затем задизейбленный инпуты отобразить с кнопками переопределить которые раздизейблят инпуты
           */}
          <NumberInput
            source="forceDays"
            label="Переопределить кол-во дней"
            fullWidth
            InputProps={{
              endAdornment: (
                <>
                  <Tooltip title="Переопределить срок, указанный в промо тарифе. Можно не передавать, тогда срок берется из тарифа">
                    <HelpOutlineIcon
                      sx={{
                        cursor: "help",
                      }}
                    />
                  </Tooltip>
                </>
              ),
            }}
          />

          <TextInput
            source="forceName"
            label="Переопределить название промо"
            fullWidth
            InputProps={{
              endAdornment: (
                <>
                  <Tooltip title="Переопределить название, указанное в тарифе. Можно не передавать, тогда название берется из тарифа. Например: 'Поставщик 2 недели'. Название попадает в список Активов клиента и в админку в список Промо.">
                    <HelpOutlineIcon
                      sx={{
                        cursor: "help",
                      }}
                    />
                  </Tooltip>
                </>
              ),
            }}
          />

          <TextInput
            source="internalComment"
            label="Внутренний комментарий"
            multiline
            minRows={2}
          />

          <BooleanInput
            source="activateSubscriptions"
            label="Автоматически активировать подписки, привязанные к Промо"
            defaultValue={true}
            helperText=""
            disabled
          />

          <FormStateURLWrapper />

          <AdditionalErrors alreadyDisplayedKeys={ALREADY_DISPLAYED_KEYS} />
        </Stack>
      </SimpleForm>
    </Create>
  );
};

function FormStateURLWrapper() {
  const {setValue, getValues, formState} = useFormContext();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const companyId = searchParams.get("companyId");
    const promoTariffId = searchParams.get("promoTariffId");
    const forceDays = searchParams.get("forceDays");
    const forceName = searchParams.get("forceName");

    const currentCompanyId = getValues("companyId");
    const currentPromoTariffId = getValues("promoTariffId");
    const currentForceDays = getValues("forceDays");
    const currentForceName = getValues("forceName");

    if (!currentCompanyId) setValue("companyId", companyId, {shouldDirty: true});
    if (!currentPromoTariffId)
      setValue("promoTariffId", promoTariffId, {shouldDirty: true});
    if (!currentForceDays) setValue("forceDays", forceDays, {shouldDirty: true});
    if (!currentForceName) setValue("forceName", forceName, {shouldDirty: true});
  }, [getValues, searchParams, setValue, formState.defaultValues]);

  return null;
}
