import {BooleanInput, Create, SimpleForm} from "react-admin";
import React from "react";
import {RadioGroupTypeOfContent} from "./components/RadioGroup";
import {VideoFields} from "./components/VideoFields";
import {ArticleFields} from "./components/ArticleFields";

export const BlogCreate = () => {
  const [typeOfContent, setTypeOfContent] = React.useState("card");

  const handleChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypeOfContent((event.target as HTMLInputElement).value);
  };

  return (
    <>
      <RadioGroupTypeOfContent value={typeOfContent} onChange={handleChangeType} />

      <Create
        title="Создание блога"
        transform={(data) => ({...data, type: typeOfContent})}
        redirect="list"
      >
        <SimpleForm>
          <BooleanInput source="status" label={"Опубликована"} name={"status"} />
          {typeOfContent === "card" ? <ArticleFields /> : <VideoFields />}
        </SimpleForm>
      </Create>
    </>
  );
};
