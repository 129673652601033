import React from "react";
import {Select, MenuItem, FormControl, InputLabel} from "@mui/material";

type CustomSelectProps<T> = {
  options: {option: T; label: React.ReactNode}[] | T[];
  onChange: (value: T) => void;
  label: string;
  value?: T | null;
  disabled?: boolean;
};

export const CustomSelect = <T,>({
  options,
  onChange,
  label,
  value = null,
  disabled = false,
}: CustomSelectProps<T>) => {
  return (
    <FormControl
      sx={{
        label: {"&[data-shrink=false]": {transform: "translate(14px, 12px) scale(1)"}},
      }}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        value={value || ""}
        onChange={(e) => {
          onChange(e.target.value as T);
        }}
        label={label}
        disabled={disabled}
        defaultValue={value}
        sx={{
          height: "42px",
          minHeight: "42px",
          maxHeight: "42px",
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option?.option || option}
            value={option?.option || option}
            sx={{justifyContent: "center"}}
          >
            {option?.label || option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
