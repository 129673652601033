import {Stack, TextField as MUITextField, Typography} from "@mui/material";
import {useState} from "react";
import {SimpleForm, useGetOne, useRecordContext, useRefresh} from "react-admin";
import {Buttons} from "./components/Buttons";
import {ROUTES} from "shared/constants/ROUTES";

export type CommentsType = {
  reason: string;
  internalNotes: string;
};

export const BlocksForm = ({
  entity,
  actions,
}: {
  entity: "COMPANY" | "USER";
  actions: ("WARN" | "BLOCK" | "UNBLOCK")[];
}) => {
  const [comments, setComments] = useState<CommentsType>({reason: "", internalNotes: ""});
  const refresh = useRefresh();

  const handleResetComments = () => {
    setComments({reason: "", internalNotes: ""});
    refresh();
  };

  const record = useRecordContext();

  const {data} = useGetOne(ROUTES.USERS_AND_COMPANY_BLOCKS_LIST.path, {
    id: record.id,
    meta: {entity},
  });

  if (record?.role === "Администратор") {
    return null;
  }

  return (
    <SimpleForm sx={{p: 0, ">div": {gap: 3}, mt: 4}} toolbar={false}>
      <Typography variant="h2-medium-14" sx={{fontWeight: 600}}>
        Заблокировать {entity === "COMPANY" ? "компанию" : "пользователя"}:
      </Typography>
      <MUITextField
        helperText={false}
        fullWidth
        name={"reason"}
        required={true}
        label={"Комментарий администратора"}
        multiline={true}
        value={comments.reason}
        onChange={(e) => setComments((prev) => ({...prev, reason: e.target.value}))}
      />
      <MUITextField
        name={"internalNotes"}
        helperText={false}
        fullWidth
        label={"Внутренний комментарий для других администраторов"}
        value={comments.internalNotes}
        onChange={(e) =>
          setComments((prev) => ({...prev, internalNotes: e.target.value}))
        }
        multiline={true}
      />
      <Stack direction="row" spacing={3}>
        {actions.map((action) => (
          <Buttons
            key={action}
            action={action}
            entity={entity}
            canActionWarn={data?.canActionWarn}
            {...comments}
            onSuccess={handleResetComments}
          />
        ))}
      </Stack>
    </SimpleForm>
  );
};
