import {
  BooleanInput,
  DateInput,
  Edit,
  ImageField,
  ImageInput,
  Labeled,
  SimpleForm,
  TextInput,
  required,
  useEditController,
  useNotify,
  useRecordContext,
} from "react-admin";
import {RichTextInput} from "ra-input-rich-text";
import Loader from "shared/components/Loader/Loader";
import {useTheme} from "@mui/material";

export const NewsEdit = () => {
  const {isLoading} = useEditController();

  return isLoading ? (
    <Loader />
  ) : (
    <Edit mutationMode={"pessimistic"} title="Редактирование новости">
      <SimpleForm>
        <BooleanInput source="status" name={"status"} label={"Опубликована"} />
        <TextInput
          fullWidth={true}
          name={"title"}
          source="title"
          label={"Заголовок"}
          validate={required()}
        />
        <RichTextInput
          name={"html"}
          source="html"
          label={"Описание"}
          validate={required()}
        />
        <WrapperImageField />
        <Labeled>
          <ImageField
            sx={{"& img": {width: "300px !important", height: "300px !important"}}}
            source="preview_url"
            label={"Текущее изображение"}
          />
        </Labeled>

        <DateInput required={true} source="time" label={"Дата"} name={"time"} />
      </SimpleForm>
    </Edit>
  );
};

export function WrapperImageField() {
  const record = useRecordContext();
  const {palette} = useTheme();
  const isRequired = record?.preview_url ? undefined : required();
  const notify = useNotify();

  const onDropRejected = () => {
    notify("Принимаются только файлы формата .jpg, .png, .jpeg", {type: "error"});
  };

  return (
    <ImageInput
      source={"preview_file"}
      accept={".jpg, .png, .jpeg"}
      label={"Новое изображение"}
      name={"preview_file"}
      validate={isRequired}
      sx={{
        ".RaFileInput-dropZone": {
          minHeight: 140,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: `0.3px dashed ${palette.border.main}`,
        },
      }}
      options={{onDropRejected}}
    >
      <ImageField
        source="src"
        sx={{"& img": {width: "300px !important", height: "300px !important"}}}
      />
    </ImageInput>
  );
}
