import {Create, SimpleForm, TextInput, NumberInput, BooleanInput} from "react-admin";
import {Stack} from "@mui/material";
import {SaveToolbar} from "shared/components/SaveToolbar/SaveToolbar";
import {RichTextInput} from "ra-input-rich-text";
import {AdditionalErrors} from "pages/father/components/AdditionalErrors";
import {MyRichTextInputToolbar} from "./components/MyRichTextInputToolbar";
import {ALREADY_DISPLAYED_KEYS} from "./constants";

export const GroupTariffsCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm toolbar={<SaveToolbar />}>
        <Stack sx={{maxWidth: "800px", width: "100%", gap: 1}}>
          <TextInput source="codename" label="Идентификатор" fullWidth />
          <TextInput source="title" label="Название" />

          <RichTextInput
            toolbar={<MyRichTextInputToolbar />}
            label="Описание"
            source="description"
          />

          <NumberInput source="sorter" label="Порядковый номер" />

          <TextInput
            source="internalComment"
            label="Внутренний комментарий"
            multiline
            minRows={2}
          />

          <BooleanInput source="publish" label="Опубликовать" />

          <AdditionalErrors alreadyDisplayedKeys={ALREADY_DISPLAYED_KEYS} />
        </Stack>
      </SimpleForm>
    </Create>
  );
};
