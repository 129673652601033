import {BooleanInput, Edit, SimpleForm, TextInput, useEditController} from "react-admin";
import Loader from "shared/components/Loader/Loader";
import {MyRichTextInput} from "./components/MyRichTextInput";
import {Box} from "@mui/material";

export const FAQEdit = () => {
  const {isLoading} = useEditController();

  return isLoading ? (
    <Loader />
  ) : (
    <Edit mutationMode={"pessimistic"}>
      <SimpleForm>
        <BooleanInput source="status" name={"status"} label={"Опубликована"} />
        <TextInput source="slug" label={"Раздел"} name={"slug"} />
        <TextInput
          fullWidth={true}
          name={"question"}
          source="question"
          label={"Вопрос"}
        />
        <TextInput
          fullWidth={true}
          name={"position"}
          source="position"
          label={"Позиция"}
        />
        <Box sx={{width: "100%"}}>
          <MyRichTextInput label="Ответ" source="answer" fullWidth />
        </Box>
      </SimpleForm>
    </Edit>
  );
};
