import {useEffect} from "react";
import {NumberInput, TextInput, required} from "react-admin";
import {useFormContext} from "react-hook-form";

export const VideoFields = () => {
  const {reset} = useFormContext();
  useEffect(() => {
    reset();
  }, []);

  return (
    <>
      <TextInput
        source="video_url"
        label={"URL видео"}
        name={"video_url"}
        validate={required()}
        fullWidth
      />

      <NumberInput source="position" label="Позиция" />
    </>
  );
};
