import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import {useEffect, useState} from "react";
import {
  SimpleForm,
  useCreate,
  useGetList,
  useNotify,
  useRecordContext,
} from "react-admin";
import {ROUTES} from "shared/constants/ROUTES";
import ClearIcon from "@mui/icons-material/Clear";
import {TariffView} from "typesFromApi/types/finApi";
import {getDurationFromDMY} from "shared/utils/getDurationFromDMY";
import {PACKAGES_NAME} from "shared/constants/packagesName";
import {useNavigate} from "react-router";
import queryString from "query-string";

export const AddPromoForCompany = () => {
  const {data} = useGetList<TariffView>(ROUTES.TARIFFS.path, {
    filter: {archived: false, promo: true},
  });

  const [selectedPromo, setSelectedPromo] = useState<TariffView | null>(null);

  const handleChange = (event: SelectChangeEvent) => {
    const {
      target: {value},
    } = event;

    const founded = data?.find((item) => item.id === value);

    if (!founded) return;

    setSelectedPromo(founded);
  };

  const handleClear = () => {
    setSelectedPromo(null);
  };

  const duration = selectedPromo ? getDurationFromDMY(selectedPromo) : "";
  const record = useRecordContext();
  const navigate = useNavigate();

  const [create, {isSuccess, isLoading, isError}] = useCreate();
  const notify = useNotify();

  useEffect(() => {
    if (isSuccess) {
      notify("Промо добавлен", {type: "success"});
      handleClear();
      return;
    }

    if (isError) {
      notify("Произошла ошибка", {type: "error"});
    }
  }, [isSuccess, isError]);

  return (
    <SimpleForm toolbar={false}>
      <Stack sx={{width: "100%"}} gap={4}>
        <Box
          sx={{
            display: "flex",
            gap: 12,
            alignItems: "center",
          }}
        >
          <FormControl
            sx={{
              maxWidth: "360px",
              width: "100%",
              ".MuiInputLabel-root": {
                transform: "translate(14px, 10px) scale(1)",
              },
            }}
          >
            <InputLabel id="availableGroups-label">Промо тарифы</InputLabel>
            <Select
              size="small"
              labelId="availableGroups-label"
              id="availableGroups"
              value={selectedPromo?.id || ""}
              onChange={handleChange}
              input={<OutlinedInput label="Промо тарифы" />}
              disabled={!data?.length}
              endAdornment={
                selectedPromo && (
                  <InputAdornment sx={{marginRight: "15px"}} position="end">
                    <IconButton onClick={handleClear}>
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                )
              }
            >
              {data?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{gap: 2, display: "flex"}}>
            <Button
              variant="reject"
              onClick={() => {
                const query = queryString.stringify({
                  companyId: record?.id,
                  promoTariffId: selectedPromo?.id,
                  forceDays: selectedPromo?.days,
                  forceName: selectedPromo?.name,
                });
                navigate(`/${ROUTES.PROMO.path}/create?${query}`);
              }}
              disabled={!selectedPromo || isSuccess}
            >
              Редактировать
            </Button>
            <Button
              variant="main"
              disabled={!selectedPromo || isLoading || isSuccess}
              onClick={() => {
                create(ROUTES.PROMO.path, {
                  data: {
                    companyId: record?.id,
                    promoTariffId: selectedPromo?.id,
                    activateSubscriptions: true,
                  },
                });
              }}
            >
              Выписать промо
            </Button>
          </Box>
        </Box>
        <Stack gap={1}>
          <Typography variant="16px" sx={{fontWeight: 600}}>
            Описание промо:
          </Typography>
          <Typography variant="h2-regular-14">{selectedPromo?.name}</Typography>
          <Typography variant="h2-regular-14">{duration}</Typography>

          <Stack gap={1}>
            <Typography variant="16px" sx={{fontWeight: 600}}>
              Включаемые пакеты:
            </Typography>
            <Stack component={"ul"} sx={{pl: 5, m: 0}}>
              {selectedPromo?.subscriptionTypes?.map((item) => (
                <Typography
                  variant="h2-regular-14"
                  component={"li"}
                  sx={{pl: 0}}
                  key={item.code}
                >
                  {PACKAGES_NAME[item.code]}
                </Typography>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </SimpleForm>
  );
};
