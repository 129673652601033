import {SimpleForm, TextInput, useNotify, useRefresh, useShowContext} from "react-admin";
import {Box, Button, Tooltip} from "@mui/material";
import {Tickets} from "shared/services/tickets";
import {useState} from "react";
import {useLocation} from "react-router-dom";

const TicketsEditButton = () => {
  const {record} = useShowContext();
  const {methods} = record;
  const {pathname} = useLocation();
  const notify = useNotify();
  const comment = useState("");
  const refresh = useRefresh();

  const ticketsIndex = pathname.indexOf("/tickets");
  const newPathname = pathname.substring(1, ticketsIndex);

  record["service"] = record?.service ? record.service : newPathname;

  if (record["service"] === "company") {
    record["service"] += "/v2";
  }

  const endPoints = {
    approve: {variant: "green", text: "Одобрить"},
    takeToWork: {variant: "info", text: "Взять в работу"},
    sendForRevision: {variant: "reject", text: "На доработку", needComment: true},
    reject: {variant: "reject", text: "Отклонить", needComment: true},
  };

  function isDisable(buttonName) {
    const isCom = endPoints[buttonName]?.needComment || false;
    return isCom && comment[0].length < 20;
  }

  return (
    <div style={{order: 1000, width: "100%"}}>
      {!!methods?.length && (
        <Box display={"flex"} gap={2} sx={{marginTop: 5}}>
          {methods?.map(
            (el, i) =>
              endPoints[el] && (
                <Tooltip
                  placement={"top"}
                  key={i}
                  title={
                    (endPoints[el]?.needComment &&
                      comment[0].length < 20 &&
                      "пожалуйста введите комментарий") ||
                    ""
                  }
                >
                  <span>
                    <Button
                      disabled={isDisable(el)}
                      onClick={() => {
                        Tickets[el](record, comment[0], notify, refresh);
                      }}
                      variant={endPoints[el]?.variant}
                    >
                      {endPoints[el]?.text}
                    </Button>
                  </span>
                </Tooltip>
              ),
          )}
        </Box>
      )}

      {!!methods?.length &&
        !(methods?.length === 1 && methods?.includes("takeToWork")) && (
          <SimpleForm sx={{padding: 0, marginTop: 2, order: 1001}} toolbar={false}>
            <TextInput
              helperText={"комментарий не менее 20 символов"}
              sx={{maxWidth: 1000}}
              onChange={(e) => comment[1](e.target.value)}
              fullWidth
              name={"comment"}
              isRequired={true}
              label={"Комментарий администратора"}
              source={"comment"}
              multiline={true}
            />
          </SimpleForm>
        )}
    </div>
  );
};

export default TicketsEditButton;
